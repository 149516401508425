<script setup lang="ts">
    import { useI18n } from 'vue-i18n';

    defineProps<{
        headingTranslationCode: string;
        subheadingTranslationCode: string;
    }>();

    const { t } = useI18n();
</script>

<template>
    <div class="content">
        <div class="success-container">
            <div class="icon-heading-container">
                <div class="icon-container">
                    <i class="pi pi-check-circle" />
                </div>
                <div class="text-container">
                    <h2 class="text-2xl-bold-line-height-auto">{{ t(headingTranslationCode) }}</h2>
                    <div class="text-base-regular-line-height-auto">{{ t(subheadingTranslationCode) }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use 'src/styling/main';

    .icon-container {
        display: flex;
        width: 100%;
        justify-content: center;
        padding: main.$spacing-5;
    }

    .content > .success-container {
        background-color: main.$color-order-successful-bg;
        color: main.$color-order-successful;
        width: 100vw;
        margin-left: calc(-50vw + 50%);
        margin-top: -24px;
        padding: main.$spacing-5;
        margin-bottom: main.$spacing-7;
    }

    .icon-heading-container {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: main.$spacing-7 auto;
        max-width: 1200px;
        flex-wrap: wrap;
        gap: main.$spacing-6;

        i {
            font-size: main.$font-size-9;
        }
    }

    .text-container {
        display: flex;
        flex-direction: column;
        gap: main.$spacing-4;
        text-align: center;
    }

    @include main.for-breakpoint-md {
        .icon-container {
            padding: 0;
            display: block;
            width: unset;
        }

        .text-container {
            text-align: left;
        }
    }
</style>
